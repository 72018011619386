<template>
    <a-modal
        :title="`${auditData.status==2?'通过':'拒绝'}`"
        width='50%'
        :visible="visible"
        :confirmLoading="confirmLoading"
        :destroyOnClose="true"
        @ok="handleAuditOne"
        @cancel="handleCancel"
    >
        <a-form :form="form">
            <!-- 父级 -->
            <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input 
                    v-decorator="['refuseReason',{rules: [{ required: auditData.status=='2' ? false : true, message: '请输入！' }]}]" 
                    type="textarea"
                    placeholder="请输入"
                />
            </a-form-item>
			<a-form-item label="凭证" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="auditData.status == '2'">
				<div style="width: 104px; height: 104px">
				    <a-upload 
				      style="margin: auto;position:relative" 
				      name="avatar" 
				      accept=".png,.jpg,.jpeg,.gif"
				      list-type="picture-card" 
				      :show-upload-list="false"
				      :customRequest="customUserRequest"
				      v-decorator="['picture']"
				    >
				      <img :src="picture" v-if="picture" style="width: 100px; height: 100px" />
				      <div v-else>
				        <a-icon :type="pictureLoading ? 'loading' : 'plus'" />
				      </div>
				    </a-upload>
				</div>
			</a-form-item>
          </a-form>
    </a-modal>
</template>

<script>
import { tixianAdminAudit } from '@/api/modular/mallLiving/tixianAdmin'
import {uploadFile} from "@/api/modular/mallLiving/uploadFile";
export default {
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            auditData:{},  //父组件传过来的status和id
            form: this.$form.createForm(this),
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
				picture: '',
				pictureLoading: false
        }
    },
    methods:{
        //初始化方法
        audit(data){
            this.visible = true
            this.auditData = data
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            this.form.setFieldsValue({
                refuseReason:''
            })
        },
		  //自定义图片上传实现
		  customUserRequest(filedata) {
		    this.pictureLoading = true
        uploadFile(filedata.file.name, filedata.file, {
          progress: function (p, checkpoint) {
            const percentage = Math.floor(p * 100)
          },
        }).then((res) => {
          console.log('res', res)
          if (res.res.statusCode == 200) {
            const list = res.res.requestUrls[0].split('?');
            if (list.length > 0) {
              this.picture = list[0]
              this.pictureLoading = false
            }
          }
        })
		  },
        handleAuditOne() {
            this.form.validateFields((errors, values)=>{
                if(!errors){
						 if(this.auditData.status == '2' && !this.picture){
							 this.$message.error("请上传凭证")
							 return
						 }
                    tixianAdminAudit({...this.auditData, refuseReason:values.refuseReason, transferVoucher: this.picture }).then((res) => {
                        if(res.success){
                            let text = this.auditData.status==2 ? "通过成功" : "拒绝成功"
                            this.$message.success(text)
                            this.$emit("ok")
                            this.handleCancel()
                        }else{
                            this.$message.error("操作失败")
                            setTimeout(()=>{
                                this.confirmLoading = false
                            },600)
                        }
                    })
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding-left:7px;
            font-size:15px;
        }
    }
    img{
        width:100px;
        height: 50px;
    }
    .flex {
        display: flex;
    }
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }
</style>
//提现管理接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-02-14
 */
// 提现管理分页查询
export function tixianAdminPageList(parameter){
    return axios({
        url: '/withdrawalRecord/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//提现管理审核
export function tixianAdminAudit(parameter){
    return axios({
        url: '/withdrawalRecord/audit',
        method: 'post',
        data: parameter
    })
}
//提现管理详情
export function tixianAdminDetail(parameter){
    return axios({
        url: '/withdrawalRecord/detail',
        method: 'post',
        params: parameter 
    })
}

export function getWithdrawalTotalNum(paramter){
	return axios({
		url: "/withdrawalRecord/total",
		method: 'post',
		data: paramter
	})
}
// //提现管理增加
// export function tixianAdminAdd(parameter){
//     return axios({
//         url: '/pfWithdrawalWay/add',
//         method: 'post',
//         data: parameter
//     })
// }
// //提现管理删除
// export function tixianAdminDelete(parameter){
//     return axios({
//         url: '/pfWithdrawalWay/delete',
//         method: 'post',
//         data: parameter
//     })
// }
// //提现管理编辑
// export function tixianAdminEdit(parameter){
//     return axios({
//         url: '/pfWithdrawalWay/edit',
//         method: 'post',
//         data: parameter
//     })
// }
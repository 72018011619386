<template>
    <a-modal
        title="详情"
        width='50%'
        :visible="visible"
        :destroyOnClose="true"
        @cancel="handleCancel"
        :footer="null"
    >
        <a-spin :spinning="confirmLoading">
            <a-row>
                <a-form layout="inline">
                    <a-col>
                        <!-- 第1行详情 -->
                        <a-row>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="用户类型">
                                    <span>{{details.userType==1?'用户':details.userType==2?'商家':''}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="用户昵称">
                                    <span>{{details.nickname}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第2行详情 -->
                        <a-row>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="申请时间">
                                    <span>{{details.createTime}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="提现金额">
                                    <span>{{details.price}} 元</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第3行详情 -->
                        <a-row>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="提现方式">
                                    <span>{{details.name}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="审核状态">
                                    <span :style="{color: details.status==1?'orange':details.status==2?'green':details.status==3?'red':''}">
                                        {{
                                            details.status==1?'待审核'
                                            :details.status==2?'已通过'
                                            :details.status==3?'已拒绝'
                                            :''
                                        }}
                                    </span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第4行详情 -->
                        <a-row>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="手续费">
                                    <span>{{details.serviceCharge}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="来源">
                                    <span>{{
                                        details.position==1?'订单收益'
                                        :details.position==2?'佣金（用户）'
                                        :details.position==3?'礼物收益'
                                        :details.position==4?'佣金（代理商）'
                                        :''
                                }}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24" v-if="details.transferVoucher">
                                <a-form-item label="转账凭证">
                                    <viewer>
                                        <img :src="details.transferVoucher" style="width: 60px; height: 60px" />
                                    </viewer>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24" v-if="details.accountOpeningSubBranch">
                                <a-form-item label="开户行信息：">
                                    <span>{{ details.accountOpeningSubBranch}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第5行详情 -->
                        <a-row>
                            <a-col :md="24" :sm="24">
                                <a-form-item  label="用户备注">
                                    <span>{{details.remarks}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第5行详情 -->
                        <a-row v-show="details.status==2 || details.status==3">
                            <a-col :md="24" :sm="24">
                                <a-form-item  label="审核备注">
                                    <span>{{details.refuseReason}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-form>
            </a-row>
        </a-spin>
    </a-modal>
</template>

<script>
import { tixianAdminDetail } from '@/api/modular/mallLiving/tixianAdmin'

export default {
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            id:'',
            details:{},
            form: this.$form.createForm(this),
        }
    },
    methods:{
        //初始化方法
        detail(record){
            this.visible = true
            this.confirmLoading = true
            //获取详情数据
            setTimeout(()=>{
                tixianAdminDetail({id: record.id}).then((res)=>{
                    if(res.success){
                        this.details = res.data
                        this.confirmLoading = false
                    }
                }).finally((res)=>{
                    setTimeout(()=>{
                        this.confirmLoading = false
                    },5000)
                })
            },100)
            
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            this.confirmLoading = false
            setTimeout(()=>{
                this.details = {} //关闭之后清空
            },100)
        }
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding:7px;
            font-size:15px;
            width:30%;
        }
    }
    img{
        width:80px;
        height: 50px;
    }
</style>